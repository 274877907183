/**
 * This file contains all possible ids 'owned' by us.
 * It's important to include all ids here so that we can
 * help protect against IDOR.
 *
 * Ideally it is kept in alphabetical order
 */
import { Static, String, Union } from "runtypes";

import { IntrinsicGroupId } from "./enums";
import {
  PaymentMethodId,
  StripeCustomerId,
  StripePriceId,
  StripeProductId,
  StripeSubscriptionId,
  StripeSubscriptionItemId,
} from "./stripeIdTypeBrands";
import { KernelImage } from "./typeBrands";

export const AppSessionAOLogId = String.withBrand("AppSessionAOLogId");
export type AppSessionAOLogId = Static<typeof AppSessionAOLogId>;

export const AppSessionCellId = String.withBrand("AppSessionCellId");
export type AppSessionCellId = Static<typeof AppSessionCellId>;

export const AppSessionGrantId = String.withBrand("AppSessionGrantId");
export type AppSessionGrantId = Static<typeof AppSessionGrantId>;

export const AppSessionId = String.withBrand("AppSessionId");
export type AppSessionId = Static<typeof AppSessionId>;

// not in alphabetical order since it depends on app session id
export const ApiClientId = Union(String.withBrand("ApiClientId"), AppSessionId);
export type ApiClientId = Static<typeof ApiClientId>;

export const BigQueryOAuthConnectionId = String.withBrand(
  "BigQueryOAuthConnectionId",
);
export type BigQueryOAuthConnectionId = Static<
  typeof BigQueryOAuthConnectionId
>;

export const BlockCellId = String.withBrand("BlockCellId");
export type BlockCellId = Static<typeof BlockCellId>;

export const CanvasElementId = String.withBrand("CanvasElementId");
export type CanvasElementId = Static<typeof CanvasElementId>;

export const CanvasLayoutId = String.withBrand("CanvasLayoutId");
export type CanvasLayoutId = Static<typeof CanvasLayoutId>;

export const CategoryId = String.withBrand("CategoryId");
export type CategoryId = Static<typeof CategoryId>;

export const CellGroupCellId = String.withBrand("CellGroupCellId");
export type CellGroupCellId = Static<typeof CellGroupCellId>;

export const CellId = String.withBrand("CellId");
export type CellId = Static<typeof CellId>;

export const ChartCellId = String.withBrand("ChartCellId");
export type ChartCellId = Static<typeof ChartCellId>;

export const ChartCellSessionStateId = String.withBrand(
  "ChartCellSessionStateId",
);
export type ChartCellSessionStateId = Static<typeof ChartCellSessionStateId>;

export const ExploreCellId = String.withBrand("ExploreCellId");
export type ExploreCellId = Static<typeof ExploreCellId>;

export const CodeCellId = String.withBrand("CodeCellId");
export type CodeCellId = Static<typeof CodeCellId>;

export const CollectionGrantId = String.withBrand("CollectionGrantId");
export type CollectionGrantId = Static<typeof CollectionGrantId>;

export const CollectionHexLinkId = String.withBrand("CollectionHexLinkId");
export type CollectionHexLinkId = Static<typeof CollectionHexLinkId>;

export const CollectionId = String.withBrand("CollectionId");
export type CollectionId = Static<typeof CollectionId>;

export const ColumnPropertiesId = String.withBrand("ColumnPropertiesId");
export type ColumnPropertiesId = Static<typeof ColumnPropertiesId>;

export const ColorPaletteId = String.withBrand("ColorPaletteId");
export type ColorPaletteId = Static<typeof ColorPaletteId>;

export const CommentId = String.withBrand("CommentId");
export type CommentId = Static<typeof CommentId>;

export const CommentReactionId = String.withBrand("CommentReactionId");
export type CommentReactionId = Static<typeof CommentReactionId>;

export const ComponentImportCellId = String.withBrand("ComponentImportCellId");
export type ComponentImportCellId = Static<typeof ComponentImportCellId>;

export const ComputeActionId = String.withBrand("ComputeActionId");
export type ComputeActionId = Static<typeof ComputeActionId>;

export const ComputeActionBatchId = String.withBrand("ComputeActionBatchId");
/**
 * A non-entity id used to batch compute actions that were queued at the same time together
 */
export type ComputeActionBatchId = Static<typeof ComputeActionBatchId>;

export const DataConnectionHexVersionLinkId = String.withBrand(
  "DataConnectionHexVersionLinkId",
);
export type DataConnectionHexVersionLinkId = Static<
  typeof DataConnectionHexVersionLinkId
>;

export const DataConnectionId = String.withBrand("DataConnectionId");
export type DataConnectionId = Static<typeof DataConnectionId>;

export const DataConnectionSchemaRefreshId = String.withBrand(
  "DataConnectionSchemaRefreshId",
);
export type DataConnectionSchemaRefreshId = Static<
  typeof DataConnectionSchemaRefreshId
>;

export const DataConnectionSchemaRefreshScheduleId = String.withBrand(
  "DataConnectionSchemaRefreshScheduleId",
);
export type DataConnectionSchemaRefreshScheduleId = Static<
  typeof DataConnectionSchemaRefreshScheduleId
>;

export const DataConnectionOAuthSettingsId = String.withBrand(
  "DataConnectionOAuthSettingsId",
);
export type DataConnectionOAuthSettingsId = Static<
  typeof DataConnectionOAuthSettingsId
>;

export const DataSourceColumnId = String.withBrand("DataSourceColumnId");
export type DataSourceColumnId = Static<typeof DataSourceColumnId>;

export const DataSourceColumnMetadataId = String.withBrand(
  "DataSourceColumnMetadataId",
);
export type DataSourceColumnMetadataId = Static<
  typeof DataSourceColumnMetadataId
>;

export const DataSourceDatabaseId = String.withBrand("DataSourceDatabaseId");
export type DataSourceDatabaseId = Static<typeof DataSourceDatabaseId>;

export const DataSourceDatabaseMetadataId = String.withBrand(
  "DataSourceDatabaseMetadataId",
);
export type DataSourceDatabaseMetadataId = Static<
  typeof DataSourceDatabaseMetadataId
>;

export const DataSourceDbtMetricId = String.withBrand("DataSourceDbtMetricId");
export type DataSourceDbtMetricId = Static<typeof DataSourceDbtMetricId>;

export const DataSourceSchemaId = String.withBrand("DataSourceSchemaId");
export type DataSourceSchemaId = Static<typeof DataSourceSchemaId>;

export const DataSourceSchemaMetadataId = String.withBrand(
  "DataSourceSchemaMetadataId",
);
export type DataSourceSchemaMetadataId = Static<
  typeof DataSourceSchemaMetadataId
>;

export const DataSourceTableId = String.withBrand("DataSourceTableId");
export type DataSourceTableId = Static<typeof DataSourceTableId>;

export const DataSourceTableMetadataId = String.withBrand(
  "DataSourceTableMetadataId",
);
export type DataSourceTableMetadataId = Static<
  typeof DataSourceTableMetadataId
>;

export type DataSourceEntityId =
  | DataSourceColumnId
  | DataSourceTableId
  | DataSourceSchemaId
  | DataSourceDatabaseId;

export const DbtMetricCellDataSourceDbtMetricLinkId = String.withBrand(
  "DbtMetricCellDataSourceDbtMetricLinkId",
);
export type DbtMetricCellDataSourceDbtMetricLinkId = Static<
  typeof DbtMetricCellDataSourceDbtMetricLinkId
>;

export const DatabricksOAuthConnectionId = String.withBrand(
  "DatabricksOAuthConnectionId",
);
export type DatabricksOAuthConnectionId = Static<
  typeof DatabricksOAuthConnectionId
>;

export const DataSourceObjectCategoryLinkId = String.withBrand(
  "DataSourceObjectCategoryLinkId",
);
export type DataSourceObjectCategoryLinkId = Static<
  typeof DataSourceObjectCategoryLinkId
>;

export const DbtMetricCellId = String.withBrand("DbtMetricCellId");
export type DbtMetricCellId = Static<typeof DbtMetricCellId>;

export const DisplayTableCellId = String.withBrand("DisplayTableCellId");
export type DisplayTableCellId = Static<typeof DisplayTableCellId>;

export const DisplayTableColumnId = String.withBrand("DisplayTableColumnId");
export type DisplayTableColumnId = Static<typeof DisplayTableColumnId>;

export const DisplayTableConfigId = String.withBrand("DisplayTableConfigId");
export type DisplayTableConfigId = Static<typeof DisplayTableConfigId>;

export const DisplayTableSessionStateId = String.withBrand(
  "DisplayTableSessionStateId",
);
export type DisplayTableSessionStateId = Static<
  typeof DisplayTableSessionStateId
>;

export const DomainDenialId = String.withBrand("DomainDenialId");
export type DomainDenialId = Static<typeof DomainDenialId>;

export const EmailDenialId = String.withBrand("EmailDenialId");
export type EmailDenialId = Static<typeof EmailDenialId>;

export const SPCSKernelInstanceId = String.withBrand("SPCSKernelInstanceId");
export type SPCSKernelInstanceId = Static<typeof SPCSKernelInstanceId>;

export const EnvVarId = String.withBrand("EnvVarId");
export type EnvVarId = Static<typeof EnvVarId>;

export const ESDocId = String.withBrand("ESDocId");
export type ESDocId = Static<typeof ESDocId>;

export const ExternalFileIntegrationId = String.withBrand(
  "ExternalFileIntegrationId",
);
export type ExternalFileIntegrationId = Static<
  typeof ExternalFileIntegrationId
>;

export const ExternalFileIntegrationHexVersionLinkId = String.withBrand(
  "ExternalFileIntegrationHexVersionLinkId",
);
export type ExternalFileIntegrationHexVersionLinkId = Static<
  typeof ExternalFileIntegrationHexVersionLinkId
>;

export const GoogleDriveOauthAccountId = String.withBrand(
  "GoogleDriveOauthAccountId",
);
export type GoogleDriveOauthAccountId = Static<
  typeof GoogleDriveOauthAccountId
>;

export const FileHexVersionLinkId = String.withBrand("FileHexVersionLinkId");
export type FileHexVersionLinkId = Static<typeof FileHexVersionLinkId>;

export const FileId = String.withBrand("FileId");
export type FileId = Static<typeof FileId>;

export const AppSessionFileId = String.withBrand("AppSessionFileId");
export type AppSessionFileId = Static<typeof AppSessionFileId>;

export type BaseFileId = FileId | AppSessionFileId;

export const FilterCellColumnId = String.withBrand("FilterCellColumn");
export type FilterCellColumnId = Static<typeof FilterCellColumnId>;

export const FilterCellId = String.withBrand("FilterCellId");
export type FilterCellId = Static<typeof FilterCellId>;

export const FilterCellSessionStateId = String.withBrand(
  "FilterCellSessionStateId",
);
export type FilterCellSessionStateId = Static<typeof FilterCellSessionStateId>;

export const FeatureSetId = String.withBrand("FeatureSetId");
export type FeatureSetId = Static<typeof FeatureSetId>;

export const GridElementId = String.withBrand("GridElementId");
export type GridElementId = Static<typeof GridElementId>;

export const GridColumnId = String.withBrand("GridColumnId");
export type GridColumnId = Static<typeof GridColumnId>;

export const GridLayoutId = String.withBrand("GridLayoutId");
export type GridLayoutId = Static<typeof GridLayoutId>;

export const GridRowId = String.withBrand("GridRowId");
export type GridRowId = Static<typeof GridRowId>;

export const GroupId = String.withBrand("GroupId");
export type GroupId = Static<typeof GroupId>;

export const GithubServerConfigId = String.withBrand("GithubServerConfigId");
export type GithubServerConfigId = Static<typeof GithubServerConfigId>;

export const HexAnalyticsId = String.withBrand("HexAnalyticsId");
export type HexAnalyticsId = Static<typeof HexAnalyticsId>;

export const HexId = String.withBrand("HexId");
export type HexId = Static<typeof HexId>;

export const HexVersionOAuthSettingsId = String.withBrand(
  "HexVersionOAuthSettingsId",
);
export type HexVersionOAuthSettingsId = Static<
  typeof HexVersionOAuthSettingsId
>;

export const HexScheduleId = String.withBrand("HexScheduleId");
export type HexScheduleId = Static<typeof HexScheduleId>;

export const ScheduleNotificationId = String.withBrand(
  "ScheduleNotificationId",
);
export type ScheduleNotificationId = Static<typeof ScheduleNotificationId>;

export const ScheduleNotificationConditionId = String.withBrand(
  "ScheduleNotificationConditionId",
);
export type ScheduleNotificationConditionId = Static<
  typeof ScheduleNotificationConditionId
>;

export const RunNotificationRecipientId = String.withBrand(
  "RunNotificationRecipientId",
);
export type RunNotificationRecipientId = Static<
  typeof RunNotificationRecipientId
>;

export const SlackChannelId = String.withBrand("SlackChannelId");
export type SlackChannelId = Static<typeof SlackChannelId>;

export const SlackTeamAuthorizationId = String.withBrand(
  "SlackTeamAuthorizationId",
);
export type SlackTeamAuthorizationId = Static<typeof SlackTeamAuthorizationId>;

export const SlackUserId = String.withBrand("SlackUserId");
export type SlackUserId = Static<typeof SlackUserId>;

export const HexAOLogId = String.withBrand("HexAOLogId");
export type HexAOLogId = Static<typeof HexAOLogId>;

export const HexVersionAOLogId = String.withBrand("HexVersionAOLogId");
export type HexVersionAOLogId = Static<typeof HexVersionAOLogId>;

export const HexVersionCategoryLinkId = String.withBrand(
  "HexVersionCategoryLinkId",
);
export type HexVersionCategoryLinkId = Static<typeof HexVersionCategoryLinkId>;

export const HexCategoryLinkId = String.withBrand("HexCategoryLinkId");
export type HexCategoryLinkId = Static<typeof HexCategoryLinkId>;

export const HexVersionId = String.withBrand("HexVersionId");
export type HexVersionId = Static<typeof HexVersionId>;

export const HexVersionSecretId = String.withBrand("HexVersionSecretId");
export type HexVersionSecretId = Static<typeof HexVersionSecretId>;

export const DockerImageRepositoryId = String.withBrand(
  "DockerImageRepositoryId",
);
export type DockerImageRepositoryId = Static<typeof DockerImageRepositoryId>;

export const DockerImageTagId = String.withBrand("DockerImageTagId");
export type DockerImageTagId = Static<typeof DockerImageTagId>;

export const ComputeHeartbeatId = String.withBrand("ComputeHeartbeatId");
export type ComputeHeartbeatId = Static<typeof ComputeHeartbeatId>;

export const KernelId = String.withBrand("KernelId");
export type KernelId = Static<typeof KernelId>;

export const KernelOperationId = String.withBrand("KernelOperationId");
export type KernelOperationId = Static<typeof KernelOperationId>;

export const KernelSourceImageId = String.withBrand("KernelSourceImageId");
export type KernelSourceImageId = Static<typeof KernelSourceImageId>;

export const KernelSourceImageVersionId = String.withBrand(
  "KernelSourceImageVersionId",
);
export type KernelSourceImageVersionId = Static<
  typeof KernelSourceImageVersionId
>;

export const LinkId = String.withBrand("LinkId");
export type LinkId = Static<typeof LinkId>;

export const MagicEventId = String.withBrand("MagicEventId");
export type MagicEventId = Static<typeof MagicEventId>;

export const HexEmbeddingContentEventId = String.withBrand(
  "HexEmbeddingContentEventId",
);
export type HexEmbeddingContentEventId = Static<
  typeof HexEmbeddingContentEventId
>;

export const MagicCompletionEventId = String.withBrand(
  "MagicCompletionEventId",
);
export type MagicCompletionEventId = Static<typeof MagicCompletionEventId>;

export const MagicCompletionContextId = String.withBrand(
  "MagicCompletionContextId",
);
export type MagicCompletionContextId = Static<typeof MagicCompletionContextId>;

export const MagicCellContentsId = String.withBrand("MagicCellContentsId");
export type MagicCellContentsId = Static<typeof MagicCellContentsId>;

export const PromptTemplateConfigId = String.withBrand(
  "PromptTemplateConfigId",
);
export type PromptTemplateConfigId = Static<typeof PromptTemplateConfigId>;

export const MapCellId = String.withBrand("MapCellId");
export type MapCellId = Static<typeof MapCellId>;

export const MapLayerId = String.withBrand("MapLayer");
export type MapLayerId = Static<typeof MapLayerId>;

export const MarkdownCellId = String.withBrand("MarkdownCellId");
export type MarkdownCellId = Static<typeof MarkdownCellId>;

export const MetricCellId = String.withBrand("MetricCellId");
export type MetricCellId = Static<typeof MetricCellId>;

export const NotificationPreferencesId = String.withBrand(
  "NotificationPreferencesId",
);
export type NotificationPreferencesId = Static<
  typeof NotificationPreferencesId
>;

export const NotionBotId = String.withBrand("NotionBotId");
export type NotionBotId = Static<typeof NotionBotId>;

export const NotionPreviewId = String.withBrand("NotionPreviewId");
export type NotionPreviewId = Static<typeof NotionPreviewId>;

export const OrgAppIconId = String.withBrand("OrgAppIconId");
export type OrgAppIconId = Static<typeof OrgAppIconId>;

export const OrgDomainId = String.withBrand("OrgDomainId");
export type OrgDomainId = Static<typeof OrgDomainId>;

export const OrgId = String.withBrand("OrgId");
export type OrgId = Static<typeof OrgId>;

export const OutputContentId = String.withBrand("OutputContentId");
export type OutputContentId = Static<typeof OutputContentId>;

export const OutputId = String.withBrand("OutputId");
export type OutputId = Static<typeof OutputId>;

export const PackageId = String.withBrand("PackageId");
export type PackageId = Static<typeof PackageId>;

export const ParameterValueId = String.withBrand("ParameterValueId");
export type ParameterValueId = Static<typeof ParameterValueId>;

export const ParameterId = String.withBrand("ParameterId");
export type ParameterId = Static<typeof ParameterId>;

export const ParentDataConnectionId = String.withBrand(
  "ParentDataConnectionId",
);
export type ParentDataConnectionId = Static<typeof ParentDataConnectionId>;

export const PinnedSchemaObjectId = String.withBrand("PinnedSchemaObjectId");
export type PinnedSchemaObjectId = Static<typeof PinnedSchemaObjectId>;

export const PivotCellId = String.withBrand("PivotCellId");
export type PivotCellId = Static<typeof PivotCellId>;

export const PresenceId = String.withBrand("PresenceId");
export type PresenceId = Static<typeof PresenceId>;

export const ProjectGrantId = String.withBrand("ProjectGrantId");
export type ProjectGrantId = Static<typeof ProjectGrantId>;

export const HexRetrievalEventId = String.withBrand("HexRetrievalEventId");
export type HexRetrievalEventId = Static<typeof HexRetrievalEventId>;

export const ProjectViewId = String.withBrand("ProjectViewId");
export type ProjectViewId = Static<typeof ProjectViewId>;

export const PromoId = String.withBrand("PromoId");
export type PromoId = Static<typeof PromoId>;

export const ExploreId = String.withBrand("ExploreId");
export type ExploreId = Static<typeof ExploreId>;

export const ReviewId = String.withBrand("ReviewId");
export type ReviewId = Static<typeof ReviewId>;

export const ReviewRequestId = String.withBrand("ReviewRequestId");
export type ReviewRequestId = Static<typeof ReviewRequestId>;

export const ReviewRequestLinkId = String.withBrand("ReviewRequestLinkId");
export type ReviewRequestLinkId = Static<typeof ReviewRequestLinkId>;

export const ReviewRequestAOLogId = String.withBrand("ReviewRequestAOLogId");
export type ReviewRequestAOLogId = Static<typeof ReviewRequestAOLogId>;

export const ScheduleNotificationRecipientId = String.withBrand(
  "ScheduleNotificationRecipientId",
);
export type ScheduleNotificationRecipientId = Static<
  typeof ScheduleNotificationRecipientId
>;

export const ScopeItemId = String.withBrand("ScopeItemId");
export type ScopeItemId = Static<typeof ScopeItemId>;

export const SearchHexesResultId = String.withBrand("SearchHexesResultId");
export type SearchHexesResultId = Static<typeof SearchHexesResultId>;

export const SecretId = String.withBrand("SecretId");
export type SecretId = Static<typeof SecretId>;

export const SharedFilterId = String.withBrand("SharedFilterId");
export type SharedFilterId = Static<typeof SharedFilterId>;

export const SharedFilterSessionStateId = String.withBrand(
  "SharedFilterSessionStateId",
);
export type SharedFilterSessionStateId = Static<
  typeof SharedFilterSessionStateId
>;

export const SharedAssetGrantId = String.withBrand("SharedAssetGrantId");
export type SharedAssetGrantId = Static<typeof SharedAssetGrantId>;

export const SlackAuthorizationId = String.withBrand("SlackAuthorizationId");
export type SlackAuthorizationId = Static<typeof SlackAuthorizationId>;

export const SnowflakeOAuthConnectionId = String.withBrand(
  "SnowflakeOAuthConnectionId",
);
export type SnowflakeOAuthConnectionId = Static<
  typeof SnowflakeOAuthConnectionId
>;

export const SnowflakeOAuthRolePreferenceId = String.withBrand(
  "SnowflakeOAuthRolePreferenceId",
);
export type SnowflakeOAuthRolePreferenceId = Static<
  typeof SnowflakeOAuthRolePreferenceId
>;

export const SqlCellId = String.withBrand("SqlCellId");
export type SqlCellId = Static<typeof SqlCellId>;

export const SqlConfigId = String.withBrand("SqlConfigId");
export type SqlConfigId = Static<typeof SqlConfigId>;

export const SemanticRetrievalEventId = String.withBrand(
  "SemanticRetrievalEventId",
);
export type SemanticRetrievalEventId = Static<typeof SemanticRetrievalEventId>;

export const SshKeyId = String.withBrand("SshKeyId");
export type SshKeyId = Static<typeof SshKeyId>;

export const StaticCellId = String.withBrand("StaticCellId");
export type StaticCellId = Static<typeof StaticCellId>;

export const StaticDataConnectionId = String.withBrand(
  "StaticDataConnectionId",
);
export type StaticDataConnectionId = Static<typeof StaticDataConnectionId>;

export const StatusId = String.withBrand("StatusId");
export type StatusId = Static<typeof StatusId>;

export const StoryElementId = String.withBrand("StoryElementId");
export type StoryElementId = Static<typeof StoryElementId>;

export const StoryLayoutId = String.withBrand("StoryLayoutId");
export type StoryLayoutId = Static<typeof StoryLayoutId>;

export const SyncRepositoryHexVersionLinkId = String.withBrand(
  "SyncRepositoryHexVersionLinkId",
);
export type SyncRepositoryHexVersionLinkId = Static<
  typeof SyncRepositoryHexVersionLinkId
>;

export const SyncRepositoryId = String.withBrand("SyncRepositoryId");
export type SyncRepositoryId = Static<typeof SyncRepositoryId>;

export const TelemetryId = String.withBrand("TelemetryId");
export type TelemetryId = Static<typeof TelemetryId>;

export const TelemetryDenialId = String.withBrand("TelemetryDenialId");
export type TelemetryDenialId = Static<typeof TelemetryDenialId>;

export const TextCellId = String.withBrand("TextCellId");
export type TextCellId = Static<typeof TextCellId>;

export const UILockId = String.withBrand("UILockId");
export type UILockId = Static<typeof UILockId>;

export const UserGroupLinkId = String.withBrand("UserGroupLinkId");
export type UserGroupLinkId = Static<typeof UserGroupLinkId>;

export const UserId = String.withBrand("UserId");
export type UserId = Static<typeof UserId>;

export const ExecutionEventId = String.withBrand("ExecutionEventId");
export type ExecutionEventId = Static<typeof ExecutionEventId>;

export const UserSnowflakeOAuthConnectionId = String.withBrand(
  "UserSnowflakeOAuthConnectionId",
);
export type UserSnowflakeOAuthConnectionId = Static<
  typeof UserSnowflakeOAuthConnectionId
>;

export const UserBigQueryOAuthConnectionId = String.withBrand(
  "UserBigQueryOAuthConnectionId",
);
export type UserBigQueryOAuthConnectionId = Static<
  typeof UserBigQueryOAuthConnectionId
>;

export const UserDatabricksOAuthConnectionId = String.withBrand(
  "UserDatabricksOAuthConnectionId",
);
export type UserDatabricksOAuthConnectionId = Static<
  typeof UserDatabricksOAuthConnectionId
>;

export const UserStarredHexLinkId = String.withBrand("UserStarredHexLinkId");
export type UserStarredHexLinkId = Static<typeof UserStarredHexLinkId>;

export const UserStarredCollectionLinkId = String.withBrand(
  "UserStarredCollectionLinkId",
);
export type UserStarredCollectionLinkId = Static<
  typeof UserStarredCollectionLinkId
>;

export const VcsAccessTokenId = String.withBrand("VcsAccessTokenId");
export type VcsAccessTokenId = Static<typeof VcsAccessTokenId>;

export const VcsConnectionId = String.withBrand("VcsConnectionId");
export type VcsConnectionId = Static<typeof VcsConnectionId>;

export const VcsPackageId = String.withBrand("VcsPackageId");
export type VcsPackageId = Static<typeof VcsPackageId>;

export const VcsPackageHexVersionLinkId = String.withBrand(
  "VcsPackageHexVersionLinkId",
);
export type VcsPackageHexVersionLinkId = Static<
  typeof VcsPackageHexVersionLinkId
>;

export const VegaChartCellId = String.withBrand("VegaChartCellId");
export type VegaChartCellId = Static<typeof VegaChartCellId>;

export const WritebackCellId = String.withBrand("WritebackCellId");
export type WritebackCellId = Static<typeof WritebackCellId>;

export const CustomCodeFormattingConfigId = String.withBrand(
  "CustomCodeFormattingConfigId",
);
export type CustomCodeFormattingConfigId = Static<
  typeof CustomCodeFormattingConfigId
>;

export const TableQueryEventId = String.withBrand("TableQueryEventId");
export type TableQueryEventId = Static<typeof TableQueryEventId>;

export const TableCoocQueryEventId = String.withBrand("TableCoocQueryEventId");
export type TableCoocQueryEventId = Static<typeof TableCoocQueryEventId>;

export const LanceTableOfDataSourceColumnsId = String.withBrand(
  "LanceTableOfDataSourceColumns",
);
export type LanceTableOfDataSourceColumnsId = Static<
  typeof LanceTableOfDataSourceColumnsId
>;

export const LanceTableOfDataSourceTablesId = String.withBrand(
  "LanceTableOfDataSourceTablesId",
);
export type LanceTableOfDataSourceTablesId = Static<
  typeof LanceTableOfDataSourceTablesId
>;

export const LanceTableOfHexesId = String.withBrand("LanceTableOfHexesId");
export type LanceTableOfHexesId = Static<typeof LanceTableOfHexesId>;

export const SchemaRefreshRequestId = String.withBrand(
  "SchemaRefreshRequestId",
);
export type SchemaRefreshRequestId = Static<typeof SchemaRefreshRequestId>;

export const SchemaRefreshEntityDiffId = String.withBrand(
  "SchemaRefreshDiffId",
);
export type SchemaRefreshEntityDiffId = Static<
  typeof SchemaRefreshEntityDiffId
>;

export const LanceTableActionId = String.withBrand("LanceTableActionId");
export type LanceTableActionId = Static<typeof LanceTableActionId>;

export const GridElementEntityId = Union(CellId, SharedFilterId);
export type GridElementEntityId = Static<typeof GridElementEntityId>;

export const NotificationUserLinkId = String.withBrand(
  "NotificationUserLinkId",
);
export type NotificationUserLinkId = Static<typeof NotificationUserLinkId>;

export const NotificationId = String.withBrand("NotificationId");
export type NotificationId = Static<typeof NotificationId>;

export const K8sResourceId = String.withBrand("K8sResourceId");
export type K8sResourceId = Static<typeof K8sResourceId>;

export const SemanticProjectId = String.withBrand("SemanticProjectId");
export type SemanticProjectId = Static<typeof SemanticProjectId>;

export const SemanticProjectVersionId = String.withBrand(
  "SemanticProjectVersionId",
);
export type SemanticProjectVersionId = Static<typeof SemanticProjectVersionId>;

export const SemanticDatasetId = String.withBrand("DatasetId");
export type SemanticDatasetId = Static<typeof SemanticDatasetId>;

export const SemanticDatasetName = String.withBrand("SemanticDatasetName");
export type SemanticDatasetName = Static<typeof SemanticDatasetName>;

export const SemanticObjectCategoryLinkId = String.withBrand(
  "SemanticObjectCategoryLinkId",
);
export type SemanticObjectCategoryLinkId = Static<
  typeof SemanticObjectCategoryLinkId
>;

export const SemanticDatasetStatusLinkId = String.withBrand(
  "SemanticDatasetStatusLinkId",
);
export type SemanticDatasetStatusLinkId = Static<
  typeof SemanticDatasetStatusLinkId
>;

export const CollapsibleCellId = String.withBrand("CollapsibleCellId");
export type CollapsibleCellId = Static<typeof CollapsibleCellId>;
// hygen-inject: cell-type-brand-id

/**
 * Represents any possible entity id in our codebase
 */
export type SuperId =
  | AppSessionAOLogId
  | AppSessionCellId
  | AppSessionFileId
  | AppSessionGrantId
  | AppSessionId
  | ApiClientId
  | BigQueryOAuthConnectionId
  | BlockCellId
  | CanvasElementId
  | CanvasLayoutId
  | CategoryId
  | CellGroupCellId
  | CellId
  | ChartCellId
  | ChartCellSessionStateId
  | CodeCellId
  | CollectionGrantId
  | CollectionHexLinkId
  | CollectionId
  | ColumnPropertiesId
  | ColorPaletteId
  | ComputeHeartbeatId
  | CustomCodeFormattingConfigId
  | CommentId
  | CommentReactionId
  | ComponentImportCellId
  | ComputeActionId
  | DatabricksOAuthConnectionId
  | DataConnectionSchemaRefreshId
  | DataConnectionSchemaRefreshScheduleId
  | DataConnectionHexVersionLinkId
  | DataConnectionId
  | DataConnectionOAuthSettingsId
  | DataSourceColumnId
  | DataSourceColumnMetadataId
  | DataSourceDatabaseId
  | DataSourceDatabaseMetadataId
  | DataSourceDbtMetricId
  | DataSourceSchemaId
  | DataSourceSchemaMetadataId
  | DbtMetricCellDataSourceDbtMetricLinkId
  | DbtMetricCellId
  | DataSourceTableId
  | DataSourceTableMetadataId
  | DataSourceObjectCategoryLinkId
  | DisplayTableCellId
  | DisplayTableColumnId
  | DisplayTableConfigId
  | DisplayTableSessionStateId
  | DomainDenialId
  | EmailDenialId
  | ExploreCellId
  | SPCSKernelInstanceId
  | K8sResourceId
  | EnvVarId
  | ESDocId
  | ExecutionEventId
  | ExternalFileIntegrationId
  | ExternalFileIntegrationHexVersionLinkId
  | FileHexVersionLinkId
  | FileId
  | FilterCellColumnId
  | FilterCellId
  | FilterCellSessionStateId
  | FeatureSetId
  | GithubServerConfigId
  | GoogleDriveOauthAccountId
  | GridElementId
  | GridColumnId
  | GridLayoutId
  | GridRowId
  | GroupId
  | HexAnalyticsId
  | HexEmbeddingContentEventId
  | HexId
  | HexAOLogId
  | HexCategoryLinkId
  | HexRetrievalEventId
  | HexVersionOAuthSettingsId
  | HexScheduleId
  | HexVersionAOLogId
  | HexVersionCategoryLinkId
  | HexVersionId
  | HexVersionSecretId
  | DockerImageRepositoryId
  | DockerImageTagId
  | IntrinsicGroupId
  | KernelId
  | KernelImage
  | KernelOperationId
  | KernelSourceImageId
  | KernelSourceImageVersionId
  | KernelSourceImageVersionId
  | LanceTableOfDataSourceColumnsId
  | LanceTableOfDataSourceTablesId
  | LanceTableOfHexesId
  | LanceTableActionId
  | LinkId
  | MagicCellContentsId
  | MagicCompletionContextId
  | MagicCompletionEventId
  | MagicEventId
  | MapCellId
  | MapLayerId
  | MarkdownCellId
  | MetricCellId
  | NotificationPreferencesId
  | NotionBotId
  | NotionPreviewId
  | OrgAppIconId
  | OrgDomainId
  | OrgId
  | OutputContentId
  | OutputId
  | PackageId
  | ParameterId
  | ParameterValueId
  | ParentDataConnectionId
  | PinnedSchemaObjectId
  | PivotCellId
  | PresenceId
  | ProjectGrantId
  | ProjectViewId
  | PromoId
  | PromptTemplateConfigId
  | ReviewId
  | ReviewRequestId
  | ReviewRequestLinkId
  | ReviewRequestAOLogId
  | ScheduleNotificationRecipientId
  | ScopeItemId
  | SearchHexesResultId
  | SemanticRetrievalEventId
  | SecretId
  | SharedFilterId
  | SharedFilterSessionStateId
  | SharedAssetGrantId
  | SlackAuthorizationId
  | SnowflakeOAuthConnectionId
  | SnowflakeOAuthRolePreferenceId
  | SqlCellId
  | SshKeyId
  | StaticCellId
  | StaticDataConnectionId
  | StatusId
  | StoryElementId
  | StoryLayoutId
  | StripeCustomerId
  | PaymentMethodId
  | StripePriceId
  | StripeProductId
  | StripeSubscriptionId
  | StripeSubscriptionItemId
  | SyncRepositoryHexVersionLinkId
  | SyncRepositoryHexVersionLinkId
  | SyncRepositoryId
  | TelemetryId
  | TelemetryDenialId
  | TextCellId
  | UILockId
  | UserGroupLinkId
  | UserId
  | UserBigQueryOAuthConnectionId
  | UserDatabricksOAuthConnectionId
  | UserSnowflakeOAuthConnectionId
  | UserStarredHexLinkId
  | UserStarredCollectionLinkId
  | VcsAccessTokenId
  | VcsConnectionId
  | VcsPackageId
  | VcsPackageHexVersionLinkId
  | VcsPackageHexVersionLinkId
  | VegaChartCellId
  | WritebackCellId
  | ScheduleNotificationId
  | ScheduleNotificationConditionId
  | SlackChannelId
  | SlackTeamAuthorizationId
  | SlackUserId
  | RunNotificationRecipientId
  | ExploreId
  | TableQueryEventId
  | TableCoocQueryEventId
  | NotificationUserLinkId
  | NotificationId
  | SqlConfigId
  | CollapsibleCellId
  // hygen-inject: cell-super-id
  | SchemaRefreshEntityDiffId
  | SemanticProjectId
  | SemanticProjectVersionId
  | SemanticDatasetId
  | SemanticObjectCategoryLinkId
  | SemanticDatasetStatusLinkId;
